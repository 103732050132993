import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
function We() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    return (
        <div className="withdraw px-[15px] py-[20px] min-h-[920px] bg-[#f2f2f2]">
            <div className="bg-[#fff] text-2xl rounded-2xl py-4 px-4 leading-10">
                <span>Công ty TNHH Unionsquare Việt Nam</span>
                <br />
                <br />
                <span>
                    Công ty TNHH Unionsquare Việt Nam thuộc tập đoàn Unionsquare - nhà bán lẻ hàng đầu của Nhật Bản và
                    châu Á - đã tạo được sự hiện diện mạnh mẽ tại Việt Nam từ năm 2011. Tại Unionsquare, tập trung vào
                    khách hàng là triết lý kinh doanh trung tâm của chúng tôi, để cam kết điều này, chúng tôi tập trung
                    vào việc nâng cao sự tiện lợi cho khách hàng và liên tục cung cấp các dịch vụ khách hàng tốt nhất.
                    Các Trung tâm mua sắm của chúng tôi tại Việt Nam gồm Celadon, Canary, Long Biên và Bình Tân, đáp ứng
                    nhu cầu của hơn 2.500 lao động. Với kế hoạch đầu tư dài hạn tại Việt Nam, chúng tôi tiếp tục mở thêm
                    3 Trung tâm Bách hóa Tổng hợp & Siêu thị (GMS) và hơn 200 cửa hàng chuyên doanh cho đến năm 2021.
                </span>
                {/* <br />
                <br />
                <span>
                    GIGAMALL là một trong số ít các dự án có mô hình bán lẻ kết hợp giữa trải nghiệm mua sắm và hoạt
                    động giải trí. Đây là Trung tâm thương mại kiểu mới đi theo xu hướng kết hợp mua sắm và trải nghiệm
                    giải trí, vốn đang là trào lưu trên thế giới Shop Entertainment.
                </span>
                <br />
                <br />
                <span>
                    Với thông điệp chủ đạo "Mỗi trải nghiệm, mỗi niềm vui", GIGAMALL không chi mang đến trải nghiệm đa
                    dạng và tiện ích phong phú cho khách mua sắm, mà còn có không gian vui chơi thân thiện cho trẻ em,
                    nơi thư giãn giải trí cho cả nhà.
                </span>
                <br />
                <br />
                <span>
                    Để mang lại trải nghiệm mới mẻ cho khách hàng, GIGAMALL tiên phong ứng dụng công nghệ vào các hoạt
                    động dịch vụ và giải trí. Ngay từ sảnh chính tòa nhà, khách tham quan có thể chiêm ngưỡng dàn đèn
                    LED chuyển màu độc đáo kết hợp với sàn tương tác LED hiện đại. Dàn đèn với tên gọi Di.
                </span>
                <br />
                <br />
                <span>
                    Crystal và sàn LED tương tác này là điểm nhấn công nghệ độc đáo, lần đầu tiên có mặt tại Việt Nam,
                    mang đến những màn trình diễn âm thanh kết hợp ánh sáng mãn nhãn cho người xem.
                </span> */}
                {/* <br />
                <br />
                <span>{t('content.we.8')}</span>
                <br />
                <br />
                <span>{t('content.we.9')}</span>
                <br />
                <br />
                <span>{t('content.we.10')}</span>
                <br />
                <br />
                <span>{t('content.we.11')}</span>
                <br />
                <br />
                <span>{t('content.we.12')}</span>
                <br />
                <br />
                <span>{t('content.we.13')}</span> */}
            </div>
        </div>
    );
}

export default We;
