import Navbar from '../components/Navbar';
import Header from '../components/Header';

function DefaultLayoutThree({ children }) {
    document.title = children.props.title;
    return (
        <div className="wrapper-three min-h-[100vh] ">
            <div className="bg-three bg-register">
                <div className="content">{children}</div>
                <Navbar link={children.props.link} title={children.props.title} />
            </div>
        </div>
    );
}

export default DefaultLayoutThree;
