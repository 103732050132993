import React, { useEffect } from 'react';
import './newsrun.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import loaIcon from '../../../../assets/images/Loa.png';

function NewsRun() {
    return (
        <div className="home-news-run">
            <div className="container">
                <div className="wrap-run">
                    <span className="title">
                        <img src={loaIcon} alt="" width={30} />
                    </span>
                    <div className="wrapper">
                        <p className="target">
                            Xin chào, nếu bạn không nhận được phản hồi sau 30p, hãy liên hệ ngay với chăm sóc khách hàng
                            telegram
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsRun;
