import Navbar from '../components/Navbar';
import Header from '../components/Header';

function DefaultLayoutTwo({ children }) {
    document.title = children.props.title;
    return (
        <div className="wrapper bg-[#1b2632]">
            <div className="bg-login ">
                <div className="content">{children}</div>
                <Navbar link={children.props.link} title={children.props.title} />
            </div>
        </div>
    );
}

export default DefaultLayoutTwo;
